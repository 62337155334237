import React, { Component } from 'react';
import SectionStaff from '../SectionStaff/section-staff';
import HeaderContact from '../HeaderContact/header-contact';
import NavBar from '../NavBar/navbar';


class PageContact extends Component {
  render() {
    return (
        <span>
        
            <NavBar />
            
            <HeaderContact />
            
            <SectionStaff />

        </span>
    );
  }
}

export default PageContact;