import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import './project-side-menu.css';


export default class ProjectSideMenu extends Component {

    renderMenuItems() {
        return this.props.categories.map((category) => {
            return <Link to={`${this.props.baseURL}/${category.url}`} className={category === this.props.category ? "active" : ""} key={category.name}>{category.name}</Link>
        });
    }

    collapseCategoryMenu() {
        window.$("#collapseMenu").collapse("hide");
    }

    render() {
        this.collapseCategoryMenu();

        const RenderedMenuItems = this.renderMenuItems()
        
        return (
            <span>

                <div className="vertical-menu d-none d-lg-block">
                    {RenderedMenuItems}
                </div>
                
                <div className="container-fluid d-lg-none d-xs-block">
                    <div className="menu-mobile">
                        <div className="collapseHeader" data-toggle="collapse" data-target="#collapseMenu">
                            <h4>{this.props.category.name}</h4>
                        </div>
                        
                        <div className="collapse" id="collapseMenu">
                            <div className="collapseMenuContainer">
                                {RenderedMenuItems}
                            </div>
                        </div>
                    </div>
                </div>

            </span>
        );  
    }

};