import React, { Component } from 'react';
import { EditableBackground, EditableContent } from '../../lib/editable-content/';
import './project-item.css';

export default class ProjectItemEditor extends Component {

	constructor(props, context) {
		super(props, context);
		this.state = { redirect: false };

		this.projekti = this.props.project;
		if (!this.projekti.nimi) this.projekti.nimi = '';
		if (!this.projekti.sijainti) this.projekti.sijainti = '';
		if (!this.projekti.vuosi) this.projekti.vuosi = '';
		if (!this.projekti.brm2) this.projekti.brm2 = '';
		if (!this.projekti.teksti) this.projekti.teksti = '';

		const dataId = 'projects';
		const groupId = props.category.url;
		this.objectId = (props.projectId) ? props.projectId : Math.round(new Date().getTime()/1000);
		this.nimiTxt = { dataId: dataId, groupId: groupId, objectId: this.objectId, contentId: 'nimi', placeholder: 'nimi', defaultTag: 'h3', singleParagraph: true, maxLines: 1, enabledTools:[] };
		this.sijaintiTxt = { dataId: dataId, groupId: groupId, objectId: this.objectId, contentId: 'sijainti', placeholder: 'sijainti', singleParagraph: true, maxLines: 1, enabledTools:[] };
		this.vuosiTxt = { dataId: dataId, groupId: groupId, objectId: this.objectId, contentId: 'vuosi', placeholder: 'vuosi', singleParagraph: true, maxLines: 1, enabledTools:[] };
		this.kokoTxt = { dataId: dataId, groupId: groupId, objectId: this.objectId, contentId: 'brm2', placeholder: 'koko', singleParagraph: true, maxLines: 1, enabledTools:[] };
		this.Txt = { dataId: dataId, groupId: groupId, objectId: this.objectId, contentId: 'teksti', placeholder: 'kuvaus', enabledTools:['Bold', 'Italic', 'Link', 'NewParagraph'] };
		this.image = { dataId: dataId, groupId: groupId, objectId: this.objectId, contentId: 'kuva', resolution: [1521, 936] };
	}

	componentDidMount() {

		this.editor = window.ContentTools.EditorApp.get();

		if (this.editor.state() === window.ContentTools.EDITOR_STATE.DORMANT) {
			const initialized = () => {
				this.editor.start();
				this.editor.ignition().unmount();
				this.editor.removeEventListener('initialized', initialized);
			};
			this.editor.addEventListener('initialized', initialized);
		} else {
			this.editor.start();
			this.editor.ignition().unmount();
		}

	}

	trimmedNodeContent(id) {
		return document.getElementById(id).childNodes[0].innerHTML.replace(/\<.*?>/g, '').replace(/\&.*?\;/g, '').trim();
	}

	onkoKaikkiTiedotAnnettu() {
		if (this.trimmedNodeContent('p-o-nimi') === '' ||
			this.trimmedNodeContent('p-o-sijainti') === '' ||
			this.trimmedNodeContent('p-o-vuosi') === '' ||
			this.trimmedNodeContent('p-o-koko') === ''
		) return false;
		return true;
	}

	saveEditor = () => {
		const projectNames = this.props.projectNames;
		const originalName = this.projekti.nimi.replace(/\<.*?>/g, '').toLowerCase().replace(/ /g, '-').replace(/&nbsp;/g, '').toLowerCase();
		const newName = this.trimmedNodeContent('p-o-nimi').replace(/ /g, '-').toLowerCase();

		//if (!this.onkoKaikkiTiedotAnnettu()) return this.editor.showMessage('Huom!', 'Anna projektille kaikki tiedot.');

		if (originalName !== newName) {
			for (const projectName of projectNames) {
				if (projectName === newName) {
					return this.editor.showMessage('Huom!', 'Kyseinen projekti on jo olemassa.');
				}
			}
		}

		let saveBtn = document.getElementById('p-save-btn');
		let cancelBtn = document.getElementById('p-cancel-btn');
		//let cancelLink = document.getElementById('p-takaisinLink');
		saveBtn.setAttribute('disabled', true);
		cancelBtn.setAttribute('disabled', true);
		//cancelLink.setAttribute('disabled', true);

		this.editor.save(false, (success) => {
			if (success || success === -1) {
				//cancelLink.click();
				window.location.href = window.location.origin+this.props.baseURL+'/'+this.props.category.url;
			} else {
				saveBtn.removeAttribute('disabled');
				cancelBtn.removeAttribute('disabled');
				//cancelLink.removeAttribute('disabled');
			}
		});
	};

	cancelEdit = () => {
		this.editor.stop(true, (cancel) => {
			if (cancel) {
				window.location.href = `${this.props.baseURL}/${this.props.category.url}`;
			}
		});
	}

	stringToEditableContent = (id, className, str, settings) => {
		var tmp = {__html: str};
		return <EditableContent id={id} className={className} dangerouslySetInnerHTML={tmp} settings={settings} />
  }

	render() {

		let mainImgStyle;
		if (this.projekti.kuva) {
			mainImgStyle = {
				backgroundImage: `url(/static/media${this.projekti.kuva}?a=0)`
			};
		}

		return (
			<div className="container-fluid project-item">

				<div className="row">
					<div className="col-12 p-img-container">
						<EditableBackground className="p-main-img" style={mainImgStyle} settings={this.image}/>
					</div>
				</div>

				<div className="row">
					<div className="col-12 p-info-box">
						{this.stringToEditableContent('p-o-nimi', 'title', this.projekti.nimi, this.nimiTxt)}
						<br/>
						<div className="subtitle">
							{this.stringToEditableContent('p-o-sijainti', null, this.projekti.sijainti, this.sijaintiTxt)}
							<p><b>  /  </b></p>
							{this.stringToEditableContent('p-o-vuosi', null, this.projekti.vuosi, this.vuosiTxt)}
							<p><b>  /  </b></p>
							{this.stringToEditableContent('p-o-koko', null, this.projekti.brm2, this.kokoTxt)}
							<p><b>  </b>brm<sup>2</sup></p></div>
						{this.stringToEditableContent('p-o-kuvaus', 'description', this.projekti.teksti, this.Txt)}
						<button onClick={this.saveEditor} id='p-save-btn' className="tallenna-btn btn btn-success">Tallenna</button>
						<button id="p-cancel-btn" onClick={this.cancelEdit} className="takaisin-btn btn btn-danger">Peruuta</button>
					</div>
				</div>

			</div>
		);
	}

};
