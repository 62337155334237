import classnames from 'classnames';
import React, { Component } from 'react';
import './form-signin.css';


export default class FormSignIn extends Component {

	constructor(props) {
		super(props);

		this.state = {
			username: '',
			password: '',
			errors: {},
			isLoading: false,
			changePassword: true,
			focusOnErrorInput: false
		}

		this.onChange = this.onChange.bind(this);
	}

	componentDidUpdate() {
		let { errors, focusOnErrorInput } = this.state;
		if (!focusOnErrorInput || !errors.input) return;
		if (errors.input.username) this.refs.usernameInput.focus();
		else if (errors.input.password) this.refs.passwordInput.focus();
		if (errors.input.password) this.setState({ password: '' });
		this.setState({ focusOnErrorInput: false });
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	render() {

		const { username, password, errors, isLoading, changePassword } = this.state;

		const usernameError = (errors.input && errors.input.username);
		const passwordError = (errors.input && errors.input.password);

		return (
			<form className={this.props.className}
				id="signInForm"
				onSubmit={this.props.onSubmit}>

				<h2 id="signInTitle">Bromeco Web Editor</h2>
				<input
					type="username"
					name="username"
					placeholder="Käyttäjätunnus"
					value={username}
					onChange={this.onChange}
					className={ classnames('form-control', 'signInInput', {'invalidInput': usernameError}) }
					autoFocus
					ref="usernameInput"/>
				<input
					type="password"
					name="password"
					value={password}
					onChange={this.onChange}
					className={ classnames('form-control', 'signInInput', {'invalidInput': passwordError}) }
					placeholder="Salasana"
					ref="passwordInput"/>
				<p className="text-danger warningText">{errors.request}</p>
				{/* <Link to={"/unohtuiko-salasana"} id="forgotPasswordLink">Unohtuiko salasana?</Link> */}
				<button id="signInBtn" ref={"signInBtn"} className="btn btn-lg btn-success btn-block" disabled={isLoading}>Kirjaudu sisään</button>
			</form>
		);
  	}

}
