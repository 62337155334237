import React, {Component} from 'react';
import styles from './button-more.css';
import {Link} from 'react-router-dom';

class ButtonMore extends Component {
    
    render() {
        
         return (


            <div className="moreButtonBox">
                 
                 <Link to={this.props.to} id="animating_btn" className={`${this.props.style} button-more`}>{this.props.title}</Link>
                 
           </div>
             
             

            );   
        
    }
    
};
            
            
            
export default ButtonMore;