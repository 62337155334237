import React from 'react';
import ComponentProject from '../ComponentProject/component-project';

const SectionProjects = () => {
    
    return (
        
        <section className="no-padding">
        
        <div className="container-fluid">
            
            <div className="row">
        
                <ComponentProject bgImage="project-section-1" title="ASUMINEN" to="/projektit/asuminen"/>
                <ComponentProject bgImage="project-section-2" title="ERITYISRYHMÄT JA KASVATUS" to="projektit/erityisryhmat-ja-kasvatus"/>
                <ComponentProject bgImage="project-section-3" title="TERVEYDENHUOLTO" to="/projektit/terveydenhuolto"/>
                <ComponentProject bgImage="project-section-4" title="TUOTANTO- JA LIIKETILAT" to="/projektit/tuotanto-ja-liiketilat"/>
                <ComponentProject bgImage="project-section-5" title="KORJAAMINEN" to="/projektit/korjaaminen"/>
                <ComponentProject bgImage="project-section-6" title="MAANKÄYTTÖ" to="/projektit/maankaytto"/>

        
            </div>
                
        </div>
        
        </section>
        
        
        
        
    
    );
    
};
            
            
            
export default SectionProjects;