import { SET_STAFF_IDS } from "../actions/types"

const initialState = []

const StaffReducer = (state = initialState, action) => {
   switch(action.type) {
      case SET_STAFF_IDS:
         return action.ids
      default:
         return state
   }
}

export default StaffReducer
