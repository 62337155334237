import React, { useEffect, useMemo, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { setProjectOrder } from '../../redux/actions/projectOrderActions';
import ProjectService from '../../services/ProjectService';
import Dialog from '../Dialog/dialog';
import ProjectListItem from '../ProjectListItemEditor/project-list-item';
import './project-list.css';


const ProjectList = ({ baseURL, category, projects }) => {

   const dispatch = useDispatch()
   const projectOrders = useSelector((state) => state.projectOrders)
   const { token } = useSelector((state) => state.auth)
   const orderFromDb = projectOrders[category.url] || []
   const [localOrder, setLocalOrder] = useState(orderFromDb) // Reorderable, "local changes"
   const [isSaving, setIsSaving] = useState(false)
   const [feedbackMessage, setFeedbackMessage] = useState(null)
   const [showConfirmDialog, setShowConfirmDialog] = useState(false)

   const dialogProps = {
      title: 'Vahvista muokkaus',
      description: 'Muutokset tulevat sivuille näkyviin välittömästi ilman julkaisua.',
      onConfirm: () => saveOrder(),
      onCancel: () => setShowConfirmDialog(false),
      onClose: () => setShowConfirmDialog(false),
      isLoading: isSaving,
   }

   const allowSave = useMemo(() => {
      if (JSON.stringify(localOrder) !== JSON.stringify(orderFromDb)) return true
      return false
   }, [localOrder, orderFromDb])

   useEffect(() => {
      if (!orderFromDb || orderFromDb.length === 0) return
      const allProjects = [...orderFromDb]

      // Add potential missing projects
      for (const project in projects) {
         if (!allProjects.includes(project)) allProjects.push(project)
      }

      setLocalOrder(allProjects) // Update local order according to the one in DB
   }, [orderFromDb, projects])

   async function saveOrder() {
      if (!category.url || !localOrder || !localOrder.length) return
      setIsSaving(true)
      const updatedOrder = await ProjectService.saveProjectOrders(category.url, localOrder, token)
      if (updatedOrder) {
         setFeedbackMessage('Muutokset tallennettu.')
         dispatch(setProjectOrder(category.url, updatedOrder))
      }
      setIsSaving(false)
      setShowConfirmDialog(false)
   }

   const reorder = (list, startIndex, endIndex) => {
      setFeedbackMessage(null)
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);

      return result;
   }

   function onDragEnd(result) {
      // dropped outside the list
      if (!result.destination) return

      const items = reorder(
        localOrder,
        result.source.index,
        result.destination.index
      )

      setLocalOrder(items)
   }

   const getItemStyle = (isDragging, draggableStyle) => ({
      userSelect: "none",
      ...draggableStyle
   })

   useEffect(() => {
      if (projects) setLocalOrder(Object.keys(projects))
   }, [category])

   const projectItems = localOrder.map((projectId, index) => {

      // If the order in database and projects aren't in sync, don't show missing item
      if (!Object.keys(projects).includes(projectId)) return

      return (
         <Draggable key={projectId} draggableId={projectId} index={index}>
            {(provided, snapshot) => (
               <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  style={getItemStyle(
                     snapshot.isDragging,
                     provided.draggableProps.style
                  )}
               >
                  <div key={projectId}>
                     <ProjectListItem
                        baseURL={baseURL}
                        category={category}
                        key={projectId}
                        projectId={projectId}
                        project={projects[projectId]}
                     />
                  </div>
               </div>
            )}
         </Draggable>
   )})

   return (
      <div className="projecItemsCotainer">
         <Dialog
            {...dialogProps}
            isShown={showConfirmDialog}
         />
         <ProjectListItem baseURL={baseURL} category={category} />
         <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId='droppable'>
               {(provided) => (
                  <div
                     {...provided.droppableProps}
                     {...provided.placeholder}
                     ref={provided.innerRef}
                  >
                     {projectItems}
                  </div>
                  )}
            </Droppable>
         </DragDropContext>

         <div className='reorder-toolbar'>
            {feedbackMessage
            ? <p style={{ color: 'green' }}>{feedbackMessage}</p>
            : <p>Voit järjestää projekteja vetämällä ja pudottamalla.</p>}
            <div>
               <button
                  className='project-order-cancel-button'
                  disabled={isSaving || !allowSave}
                  onClick={() => setLocalOrder(orderFromDb)}
                  title={!allowSave && 'Ei tallentamattomia muutoksia'}
               >
                  Hylkää muutokset
               </button>
               <button
                  className='project-order-save-button'
                  disabled={isSaving || !allowSave}
                  onClick={() => setShowConfirmDialog(true)}
                  title={!allowSave && 'Ei tallentamattomia muutoksia'}
                  style={{ marginRight: 20 }}
               >
                  Tallenna järjestys
               </button>
            </div>

         </div>


      </div>
   )

}

export default ProjectList
