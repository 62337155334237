import axios from 'axios'

const corsAnywhereUrl = process.env.REACT_APP__CORS_ANYWHERE || ''
const API_URL = corsAnywhereUrl + 'https://8elmbyr4ch.execute-api.eu-central-1.amazonaws.com/production/project-orders'

export default class ProjectService {

   /**
    * @typedef ProjectOrder
    * @property {string} category
    * @property {string[]} order ID's in a specific order
    */

   /**
    * @param {ProjectOrder[]} order
    */
   static async saveProjectOrders(category, order, token) {
      const { data } = await axios.post(API_URL, { category, order }, {
         headers: {
            'Authorization': token,
         }
      })
      if (data.order) return data.order
      return []
   }

   static async getProjectOrders() {
      const { data } = await axios.get(API_URL)
      return data
   }

}
