import React from 'react';
import './footer.css';
import BromecoLogo from '../../assets/bromeco-logo-white.png'
import Logo from '../../assets/vihanto-logo-white.svg'
import { Link } from 'react-router-dom';
import { EditableContent } from '../../lib/editable-content/';

import Logo1 from '../../assets/logo1.png'
import Logo2 from '../../assets/Logo2.png'
// import Logo3 from '../../assets/logo3.png'
import Logo4 from '../../assets/Logo4.png'


const Footer = () => {

    if (window.location.href.includes("projektit") ||
        window.location.href.includes("kirjaudu") ||
        window.location.href.includes("unohtuiko-salasana")
    ) return null;

    const pageId = 'main-page';
    const text = { pageId: pageId, contentId: 'footerYhteystiedot', enabledTools:['Bold', 'Italic', 'Link', 'NewParagraph'] };

    return (
        
        <footer>
        
            <div className="container-fluid footer-inner-container">
                <div className="row">
                    <div className="col-sm-12 col-md-7 col-lg-5 footer-box">
                        <h5>Yhteystiedot</h5>
                        <EditableContent settings={text}/>
                    </div>
                    
                    
                    <div className="col-sm-12 col-md-5 col-lg-3 footer-box">
                        <h5>Navigointi</h5>
                        <ul className="footer-ul">
                              <li className="">
                                <Link to="/" className="footer-link">Etusivu</Link>
                              </li>
                                <li className="">
                                <Link to="/tietoa-meista" className="footer-link">Tietoa Meistä</Link>
                              </li>
                              <li className="">
                                <Link to="/projektit" className="footer-link">Projektit</Link>
                              </li>
                                <li className="">
                                <Link to="/yhteystiedot" className="footer-link">Yhteystiedot</Link>
                              </li>
                        </ul>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-4 logo-box footer-box">
                        <div className="logo-container center-this">
                            <img src={Logo}></img>
                        </div>
                    </div>
                    
                </div>
                
                
                
            </div>
        
            <span>
				<div className="row logo-row">
					<img src={Logo4} className="row-logo"/>
					<img src={Logo2} className="row-logo"/>
					<a href="https://www.tilaajavastuu.fi/?yt=1589168-6">
						<img src="https://www.tilaajavastuu.fi/wp-content/uploads/2013/10/lk_logo.png" alt="LuotettavaKumppaniyritys" className="row-logo"/>
					</a>
					<img src={Logo1} className="row-logo"/>
				</div>
            </span>

            <span>
				<div className="row">
					<div className="center-this row-copyright">
						©2018 Arkkitehtuuritoimisto Vihanto & Co Oy
					</div>
				</div>
            </span>

            <span>
				<div className="row bromeco-logo-row">
					<a href="https://bromeco.fi" target="_blank"><img className="bromeco-logo-img" src={BromecoLogo}></img></a>
				</div>
            </span>

        </footer>
    
    );
    
};
            
            
            
export default Footer;