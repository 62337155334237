import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setStaffIds } from '../../redux/actions/staffActions';
import { EditableBackground, EditableContent } from '../../lib/editable-content';
import StaffService from '../../services/StaffService';
import { useIsEditing, useIsPublishing, useIsSaving } from '../../utils/contentEditor';
import SectionTitle from '../SectionTitle/section-title';
import './component-contact-avatars.scss';

const ComponentContactAvatars = () => {

	const staff = useSelector((state) => state.staffIds)
	const pageId = 'contact'

	const [selectedStaffId, setSelectedStaffId] = useState(null)

	const dispatch = useDispatch()
   const isEditing = useIsEditing() // true = content tools' editing is enabled
   const isEditorSaving = useIsSaving()
   const isEditorPublishing = useIsPublishing()

   const isInitialLoad = useRef(true)

   const [preSaveState, setPreSaveState] = useState(null)
   const [shouldRevert, setShouldRevert] = useState(true)

   const { isAuthenticated, token } = useSelector((state) => state.auth)

	// Get staff
	useEffect(() => {
		StaffService.getStaff()
			.then((ids) => dispatch(setStaffIds(ids)))
			.catch((err) => console.error("Couldn't get staff ID's", err))
	}, [])

   const canAddNew = React.useMemo(() => {
      if (!isEditing) return false
      if (staff && staff.length > 0) {
         if (staff.find((m) => (m.isShown === false))) return true
         return false
      }
      return false
   }, [staff, isEditing])

   // If true, will save staff changes when confirming changes on content tools
   const shouldSaveStaff = React.useMemo(() => {
      return ((preSaveState !== staff) && isEditing)
   }, [preSaveState, staff, isEditing])

   // Save staff along with other things when staff order etc has changed
   useEffect(() => {
      if (isEditorSaving && shouldSaveStaff) saveStaff()
   }, [isEditorSaving, shouldSaveStaff])

   // Fire publish function when page is being published via content tools
   useEffect(() => {
      if (isEditorPublishing) {
         const staffToPublish = [...staff].map((item, index) => ({ ...item, order: index + 1 }))
         StaffService.publishStaff(staffToPublish, token)
            .catch((err) => console.error("Couldn't publish staff items", err))
      }
   }, [isEditorPublishing])

   // Turn off initial loading flag
   useEffect(() => {
      if (staff.length > 0 && isInitialLoad.current) {
         isInitialLoad.current = false
         setPreSaveState(JSON.stringify(staff))
      }
   }, [staff, isInitialLoad])

   // Revert changes
   useEffect(() => {
      if (!isEditing) setSelectedStaffId(null)
      if ((staff && preSaveState) && !isEditing && (staff !== preSaveState) && shouldRevert) {
         dispatch(setStaffIds(JSON.parse(preSaveState)))
      }
   }, [isEditing])

   // Save changes to staff, disable save button
   // TODO Use with content tools' save button
   function saveStaff() {
      // Set order numbers
      const staffToSave = [...staff].map((item, index) => ({ ...item, order: index + 1 }))
      setShouldRevert(false)
      StaffService.saveStaff(staffToSave, token)
         .then(() => setPreSaveState(JSON.stringify(staffToSave)))
         .catch((err) => console.error("Couldn't save staff", err))
         .finally(() => setShouldRevert(true))
   }

   // Add an empty staff entry to {province}
   function addStaff() {
      const shown = [...staff].filter((member) => (member.isShown))
      const hidden = [...staff].filter((member) => (!member.isShown))
      hidden[0].isShown = true
      shown.push(hidden[0])
      hidden.splice(0, 1)
      const all = [...shown, ...hidden]
      dispatch(setStaffIds(all))
   }

   // "Remove" a staff item by hiding it
   function removeStaff() {
      const members = [...staff]
      const indexOfMember = members.findIndex((m) => (m.staffId === selectedStaffId))
      members[indexOfMember].isShown = false
      const shown = [...members].filter((member) => (member.isShown))
      const hidden = [...members].filter((member) => (!member.isShown))
      const all = [...shown, ...hidden]
      setSelectedStaffId(null)
      dispatch(setStaffIds(all))
   }

   // Move a staff item either back or forward
   function handleMoveItem(direction) {

      const shown = [...staff].filter((member) => (member.isShown))
      const hidden = [...staff].filter((member) => (!member.isShown))
      const index = shown.findIndex((member) => (member.staffId === selectedStaffId))

      switch (direction) {
         case 'back': {
            if (index > 0) {
               const tmp = shown[index - 1]
               shown[index - 1] = shown[index]
               shown[index] = tmp
            }
            break
         }
         case 'forward': {
            if (index < (shown.length - 1)) {
               const tmp = shown[index + 1]
               shown[index + 1] = shown[index]
               shown[index] = tmp
            }
            break
         }
         default: break
      }
      dispatch(setStaffIds([...shown, ...hidden]))
   }

	return (
		<div className="inner-container" style={{width: "100%"}}>

			{!!selectedStaffId && isEditing && <div className="staff-edit-controls">
				<button

					style={{ marginRight: 12 }}
					onClick={() => handleMoveItem('back')}
					disabled={isEditorSaving || !isEditing}
				>Siirrä taakse
				</button>
				<button
					style={{ marginRight: 12 }}
					onClick={() => handleMoveItem('forward')}
					disabled={isEditorSaving || !isEditing}
				>Siirrä eteen
				</button>
				<button
					className="button-red"
					onClick={() => removeStaff(selectedStaffId)}
					disabled={isEditorSaving}
				>Poista
				</button>
			</div>}

			<SectionTitle text="Henkilöstö"/>

			<div className="person-container row" style={{marginLeft: "auto", marginRight: "auto"}}>

				{staff.length && [...staff]
               .map(({ staffId, isShown }) => {

					return (
						<StaffItem
							avatarSettings={{ pageId, contentId: `${staffId}_avatar`, resolution: [300, 400] }}
							textSettings={{ pageId, contentId: `${staffId}_text`, headingTag: 'h3' }}
							onSelect={() => {
								if (!isEditing) return
								selectedStaffId === staffId
								? setSelectedStaffId(null)
								: setSelectedStaffId(staffId)
							}}
							isSelected={selectedStaffId === staffId}
                     isShown={isShown}
                     key={staffId}
						/>
					)
				})}

				{canAddNew && (
					<div
						className="add-new-container"
						onClick={() => addStaff()}
					>
						<div className="add-new-circle">+</div>
						Lisää uusi
					</div>
				)}
			</div>
		</div>
	);

};

const StaffItem = ({ avatarSettings, textSettings, onSelect, isSelected, isShown }) => {

	return (
		<div
			onClick={onSelect}
			className="col-xs-12 col-md-4 col-sm-6 col-avatar"
			key={avatarSettings}
         style={{
            display: !isShown ? 'none' : 'block',
            justifyContent: 'center',
            textAlign: 'center',
         }}
		>
			<div
				className="avatar-container"
				style={{ backgroundColor: isSelected ? 'rgb(244, 244, 244)' : null }}
			>
				<EditableBackground
					className="avatar-div"
					settings={avatarSettings}
				/>
				<EditableContent
					settings={textSettings}
				/>
			</div>
		</div>
	)

}

export default ComponentContactAvatars;
