import React from 'react';
import {LoadContent, LoadBackground} from '../content-loader/';
import './imagery-and-video.css';
import './content-tools.min.css';
import './content-tools.js';


export const EditableContent = props => {
	let settings = props.settings;
	if (!settings) throw Error('settings parameter is missing.');
	if (settings.disabledTools && settings.enabledTools) throw new Error("disabledTools and enabledTools can't be used simultaneously.");
	if (settings.enabledTools && settings.enabledTools.length === 0) settings.enabledTools = ['none'];
	return generateLoaderElement(props, 'Content');
}


export const EditableBackground = props => {
	let settings = props.settings;
	if (!settings) throw Error('settings parameter is missing.');
	if (!settings.resolution) throw Error('resolution parameter is missing.');
	else if (!settings.resolution instanceof Array || settings.resolution.length !== 2) throw Error('resolution parameter has invalid data.');
	return generateLoaderElement(props, 'Background');
}


/**
 * Editable components are kept cached in this variable so if react tries to update them when editing is enabled, return the earlier element so changes that ContentTools did for them won't be overwritten
 * @type {Object<string, Object<string, JSX.Element>} { <pageId>: { <contentId>: <Component> } }
 */
 const editableComponents = {}

const generateLoaderElement = (props, type) => {
	const settings = props.settings
	if (!settings) throw new Error("settings parameter is missing.")
	const { pageId, contentId, dataId, objectId, groupId } = settings
	//if ((!dataId || !groupId || !objectId || !contentId) && (!pageId || !contentId)) throw new Error("settings parameter must contain 'dataId, groupId, objectId and contentId' or 'pageId and contentId'")
	//if (dataId && pageId) throw new Error("settings parameter must contain 'dataId, groupId, objectId and contentId' or 'pageId and contentId' not all of them")

	let attributes = {}
	let component
	for (const name in props) {
		if (name === 'settings' || name === 'children') continue;
		attributes[name] = props[name];
	}
	if (['LOCKED', 'EDITING'].includes(window.ContentTools.EditorApp.get().getState()) && editableComponents[settings.pageId][settings.contentId]) { // See editableComponent var
		return editableComponents[settings.pageId][settings.contentId]
	}

	attributes['data-ct-page-id'] = settings.pageId;
	attributes['data-ct-content-id'] = settings.contentId;
	attributes['data-ct-data-id'] = settings.dataId;
	attributes['data-ct-group-id'] = settings.groupId;
	attributes['data-ct-object-id'] = settings.objectId;
	attributes['data-ct-placeholder'] = settings.placeholder;
	attributes.settings = {}
	attributes.settings.tag = settings.tag;
	attributes.settings.pageId = pageId;
	attributes.settings.contentId = contentId;
	attributes.settings.dataId = dataId;
	attributes.settings.groupId = groupId;
	attributes.settings.objectId = objectId;

	switch (type) {
		case 'Content':
			attributes['data-ct-editable-content'] = true;
			attributes['data-ct-default-tag'] = settings.defaultTag;
			attributes['data-ct-single-paragraph'] = settings.singleParagraph;
			attributes['data-ct-max-lines'] = settings.maxLines;
			attributes['data-ct-heading-tag'] = settings.headingTag;
			attributes['data-ct-enabled-tools'] = settings.enabledTools;
			attributes['data-ct-disabled-tools'] = settings.disabledTools;
			component = <LoadContent {...attributes}>{props.children}</LoadContent>
			break
		case 'Background':
			attributes['data-ct-editable-background'] = true;
			attributes['data-ct-image-resolution'] = settings.resolution;
			component = <LoadBackground {...attributes}>{props.children}</LoadBackground>
			break
		default:
			component = null
	}

	if (!editableComponents[settings.pageId]) editableComponents[settings.pageId] = {}
	editableComponents[settings.pageId][settings.contentId] = component

	return component
}

export const setDataId = (element, dataId) => {
	element.setAttribute('data-ct-data-id', dataId)
}
export const setGroupId = (element, groupId) => {
	element.setAttribute('data-ct-group-id', groupId)
}
export const setObjectId = (element, objectId) => {
	element.setAttribute('data-ct-object-id', objectId)
}
