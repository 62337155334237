import { SET_PROJECT_ORDER } from "../actions/types"

const initialState = {}

const ProjectOrderReducer = (state = initialState, action) => {
   switch(action.type) {
      case SET_PROJECT_ORDER: {
         const stateCopy = JSON.parse(JSON.stringify(state))
         stateCopy[action.category] = action.order
         return stateCopy
      }
      default:
         return state
   }
}

export default ProjectOrderReducer
