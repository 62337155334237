import React from 'react';
import './section-about-us.css';
import ButtonMore from '../ButtonMore/button-more'
import { EditableContent, EditableBackground } from '../../lib/editable-content/';

const SectionAboutUs = () => {
	
		const pageId = 'main-page';
		const text = { pageId: pageId, contentId: 'aboutUsTxt', headingTag:'h3', enabledTools:['Heading', 'Subheading', 'Paragraph', 'Bold', 'Italic', 'Link', 'NewParagraph' ] };
		const image = { pageId: pageId, contentId: 'aboutUsImg',  resolution:[1920, 1080] };
		
	return (
		
		<section className="about-us">
		
		  <div className="container-fluid container-about-us">
				
			  <div className="row about-row">
				  
					  
				  
				  	<div className="col-sm-12 col-lg-5 center-this">
				  
						<div className="about-text-container">
								<EditableContent settings={text}/>
								<br/>
							<div className="more-button-container">
								<ButtonMore to="/tietoa-meista" title="Lue Lisää"/>
							</div>
								
						</div>
				  	</div>

				  
				  <div className="col-sm-12 col-lg-7 center-this-col-lg">
						<div className="aboutImageContainer">
							<EditableBackground className="aboutUsImageBox center-this" settings={image}/>
						</div>
					</div>

				</div>              
			</div>
		</section>
	
	);
	
};
			
			
			
export default SectionAboutUs;