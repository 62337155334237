import { useCallback, useEffect, useState } from "react";

const CONTENT_EDITOR_MEDIA_STORAGE_URL = 'https://www.editor.arkvihanto.fi/static/media/'; // TODO: remove ???

const SAVE_API = 'https://8elmbyr4ch.execute-api.eu-central-1.amazonaws.com/production/save';
const PUBLISH_API = 'https://8elmbyr4ch.execute-api.eu-central-1.amazonaws.com/production/publish';


export function initContentEditor(authToken, onSignOutListener) {

	if (document.readyState !== "complete") {
		const onLoad = () => {
			initContentEditor(authToken, onSignOutListener);
			window.removeEventListener("load", onLoad);
		}
		return window.addEventListener("load", onLoad);
	}

	var editor = window.ContentTools.EditorApp.get();
	editor.init('*[data-ct-editable-content]', '*[data-ct-editable-background]', 'data-ct-page-id', 'data-ct-data-id', 'data-ct-group-id', 'data-ct-object-id', 'data-ct-content-id', CONTENT_EDITOR_MEDIA_STORAGE_URL, true, false, true, false);
	editor.addEventListener('save', (ev) => {
		const payload = ev.detail(),
				successCallback = ev.callback();

		//updatePageDataJSON(payload);
		//console.log(payload);
		//return successCallback(true);
		if ((!payload.pages || Object.keys(payload.pages).length === 0) && (!payload.data || Object.keys(payload.data).length === 0) && (!payload.dataToRemove || Object.keys(payload.dataToRemove).length === 0)) return successCallback(-1);
		const onStateChange = (ev) => {
			if (ev.target.readyState === 4) {
				if (ev.target.status === 200) {
					updatePageDataJSON(payload);
					setTimeout(() => {
						successCallback(true);
				  	}, 3000);
				} else {
					successCallback(false);
				}
			}
		};
		var xhr = new XMLHttpRequest();
		xhr.addEventListener("readystatechange", onStateChange);
		if (ev.onProgress) xhr.upload.addEventListener('progress', ev.onProgress());
		xhr.open("POST", SAVE_API);
		xhr.setRequestHeader("Content-Type", "application/json");
		xhr.setRequestHeader("Authorization", authToken);
		xhr.send(JSON.stringify(payload));
	});

	editor.addEventListener('publish', (ev) => {
		const successCallback = ev.callback();
		const onStateChange = (ev) => {
			if (ev.target.readyState === 4) {
				if (ev.target.status === 200) {
					successCallback(true);
				} else {
					successCallback(false);
				}
			}
		};
		var xhr = new XMLHttpRequest();
		xhr.addEventListener("readystatechange", onStateChange);
		if (ev.onProgress) xhr.upload.addEventListener('progress', ev.onProgress());
		xhr.open("POST", PUBLISH_API);
		xhr.setRequestHeader("Content-Type", "application/json");
		xhr.setRequestHeader("Authorization", authToken);
		xhr.send();
	});
	window.addEventListener('popstate', () => {
			const editor = window.ContentTools.EditorApp.get();
			if (!editor.editingStartedUrl()) {
				window.location.replace(document.URL);
			}
	});

	const onSignOut = () => {
		editor.unmount();
		onSignOutListener();
		editor.removeEventListener('signOut', onSignOut);
	}
	editor.addEventListener('signOut', onSignOut);
}


export function useIsEditing() {
	const [isEditing, setIsEditing] = useState(false)
	const startCb = useCallback(() => setTimeout(() => setIsEditing(true), 10)) // HACK: The event triggers before content tools has update components. We want to update isEditing status after content-tools is ready
	const stopCb = useCallback(() => setIsEditing(false))
	useEffect(() => {
		const editor = window.ContentTools.EditorApp.get()
		if (editor !== undefined) {
			editor.addEventListener('started', startCb)
			editor.addEventListener('stopped', stopCb)
			return () => {
				editor.removeEventListener('started', startCb)
				editor.removeEventListener('stopped', stopCb)
			}
		}
	}, [])
	return isEditing
}

export function useIsSaving() {
	const [isSaving, setIsSaving] = useState(false)
	const startCb = useCallback(() => setIsSaving(true))
	const stopCb = useCallback(() => setIsSaving(false))
	useEffect(() => {
		const editor = window.ContentTools.EditorApp.get()
		if (editor !== undefined) {
			editor.addEventListener('saving', startCb)
			editor.addEventListener('saved', stopCb)
			return () => {
				editor.removeEventListener('saving', startCb)
				editor.removeEventListener('saved', stopCb)
			}
		}
	}, [])
	return isSaving
}

export function useIsPublishing() {
	const [isPublishing, setIsPublishing] = useState(false)
	const publishCb = useCallback(() => {
		// We only want the information that the user has clicked "publish",
		// knowing if it passed/failed isn't required
		setIsPublishing(true)
		setTimeout(() => {
			setIsPublishing(false)
		}, 100)
	})
	useEffect(() => {
		const editor = window.ContentTools.EditorApp.get()
		if (editor) {
			editor.addEventListener('publish', publishCb)
			return () => editor.removeEventListener('publish', publishCb)
		}
	}, [])
	return isPublishing
}

function updatePageDataJSON(saveData) {

	const pages = saveData.pages;
	if ((pages && pages.constructor === Object && Object.keys(pages).length !== 0)) {
		for (const pageId in pages) {

			if (!window.pageDataJSON.pages) window.pageDataJSON.pages = {};
			if (!window.pageDataJSON.pages[pageId]) window.pageDataJSON.pages[pageId] = {};

			const regions           = pages[pageId].regions,
					backgroundHolders = pages[pageId].backgroundHolders;

			if (regions) {
				for (const name in regions) {
					if (!window.pageDataJSON.pages[pageId].regions) window.pageDataJSON.pages[pageId].regions = {};
					window.pageDataJSON.pages[pageId].regions[name] = { html: regions[name].editorData };
				}
			}
			if (backgroundHolders) {
				for (const name in backgroundHolders) {
					if (!window.pageDataJSON.pages[pageId].backgroundHolders) window.pageDataJSON.pages[pageId].backgroundHolders = {};
					window.pageDataJSON.pages[pageId].backgroundHolders[name] = { src: backgroundHolders[name].editorData + '?a=0' };
				}
			}

		}
	}

	const dataToUpload = saveData.data;
	if (dataToUpload && dataToUpload.constructor === Object && Object.keys(dataToUpload).length !== 0) {

		for (const dataId in dataToUpload) {
			const _data = dataToUpload[dataId];
			if (!window.pageDataJSON[dataId]) window.pageDataJSON[dataId] = {};
			for (const groupId in _data) {
				const group = _data[groupId];
				if (!window.pageDataJSON[dataId][groupId]) window.pageDataJSON[dataId][groupId] = {};

				for (const objectId in group) {
					let _object = group[objectId];
					if (!window.pageDataJSON[dataId][groupId][objectId]) window.pageDataJSON[dataId][groupId][objectId] = {};

					for (const objectDataKey in _object) {
						window.pageDataJSON[dataId][groupId][objectId][objectDataKey] = _object[objectDataKey].editorData;
					}

				}

			}

		}

	}

	const dataToRemove = saveData.dataToRemove;
	if (dataToRemove && dataToRemove.constructor === Object && Object.keys(dataToRemove).length !== 0) {
		for (const dataId in dataToRemove) {
			const _data = dataToRemove[dataId];
			if (!window.pageDataJSON[dataId]) window.pageDataJSON[dataId] = {};

			for (const groupId in _data) {
				const group = _data[groupId];
				if (!window.pageDataJSON[dataId][groupId]) window.pageDataJSON[dataId][groupId] = {};

				for (const objectName of group) {
					if (!window.pageDataJSON[dataId][groupId][objectName]) window.pageDataJSON[dataId][groupId][objectName] = {};
					delete window.pageDataJSON[dataId][groupId][objectName];
				}

			}

		}
	}
}
