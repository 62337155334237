import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import ProjectSideMenu from '../ProjectSideMenuEditor/project-side-menu';
import './page-projects.css';
import NavBar from '../NavBar/navbar';
import ProjectListEditor from '../ProjectListEditor/project-list.js';
import ProjectItemEditor from '../ProjectItemEditor/project-item.js';
import GetData from '../../lib/content-loader/';

class PageProjects extends Component {

    constructor(props) {
        super(props);
        this.state = { projectsDataReady: false }
        this.projectsData = null;
        this.categories = [
            { url:'asuminen', name:'Asuminen'},
            { url:'hoivatilat', name:'Hoivatilat'},
            { url:'terveydenhuolto', name:'Terveydenhuolto'},
            { url:'tuotanto-ja-liiketilat', name:'Tuotanto- ja liiketilat'},
            { url:'korjaaminen', name:'Korjaaminen'},
            { url:'maankaytto', name:'Maankäyttö'},
            { url: 'muut-kohteet', name: 'Muut kohteet' },
        ];
        this.defaultCategory = this.categories[0];
    }

    componentDidMount() {
        this.getProjectsData();
    }


    getProjectsData() {
        GetData("projects", function(data) {
            this.projectsData = data;
            this.setState({projectsDataReady: true});
        }.bind(this));
    }

    nameToUrl(name) {
        return name.replace(/<.*?>/g, '').replace(/&.*?;/g, '').trim().replace(/ /g, '-').replace(/\//g, '%2F').toLowerCase();
    }


    render() {

        if (!this.state.projectsDataReady) return null;

        const baseURL = this.props.match.url.replace('/'+this.props.match.params.category, '').replace('/'+this.props.match.params.project, '');

        let projects, category, project, projectId;
        let projectNames = [];

        for (const _category of this.categories) {
            if (_category.url === this.props.match.params.category) {
                category = _category;
                break;
            }
        }
        if (category) {
            projects = (this.projectsData[category.url]) ? this.projectsData[category.url] : null;

            if (this.props.match.params.project) {
                console.log(this.props.match.params.project)
                for (const _projectId in projects) {
                    projectNames.push(this.nameToUrl(projects[_projectId].nimi));
                }
                if (this.props.match.params.project !== 'uusi-projekti') {
                    for (const _projectId in projects) {
                        const _project = projects[_projectId];
                        if (this.nameToUrl(_project.nimi) === this.nameToUrl(this.props.match.params.project)) {
                            projectId = _projectId;
                            project = _project;
                            break;
                        }
                    }
                    if (!project) {
                        console.log('no project')
                        return <Redirect from={this.props.match.url} to={baseURL + '/' + category.url} />
                    }
                } else {
                    project = {};
                }
            }
        } else {
            return <Redirect from={this.props.match.url} to={baseURL + '/' + this.defaultCategory.url} />
        }

        const projectListEditor = (!project) ? <span><div className="topCategoryTitle d-none d-lg-block"><h2>{category.name}</h2></div><ProjectListEditor baseURL={baseURL} category={category} projects={projects} /></span> : null;
        const projectItemEditor = (project) ? <ProjectItemEditor baseURL={baseURL} category={category} projectId={projectId} project={project} projectNames={projectNames} /> : null;

        return (
            <span>

                <NavBar />

                <div className="container-fluid row">

                    <div className="col-md-12 col-lg-2 side-menu-container">
                        <ProjectSideMenu baseURL={baseURL} categories={this.categories} category={category} projects={projects} project={project} />
                    </div>

                    <div className="col-md-12 col-lg-8">
                        {projectListEditor}
                        {projectItemEditor}
                    </div>

                </div>

            </span>
        );
    }
}

export default PageProjects;
