import { combineReducers } from 'redux';
import auth from './auth';
import ProjectOrderReducer from './projectOrderReducer';
import StaffReducer from './staffReducer';

export default combineReducers({
    auth,
    staffIds: StaffReducer,
    projectOrders: ProjectOrderReducer,
});
