import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class RouteChangeListener extends Component {

   constructor(props) {
      super(props);
   }

   componentDidUpdate(prevProps) {
      if (this.props.location !== prevProps.location) {
         this.props.onRouteChanged(prevProps.location, this.props.location);
      }
   }

   render() {
      return null;
   }

}

export default withRouter(RouteChangeListener);
