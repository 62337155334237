import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './project-list-item.css';

export default class ProjectItemEditor extends Component {

	constructor(props) {
		super(props);
		this.state = {
			removed: false,
			useOriginalImg: false
		};
	}


	deleteProject() {
		const props = this.props;
		const category = props.category.url;
		const projectId = props.projectId;

		let deleteBtn = document.getElementById('p-delete-btn');
		deleteBtn.setAttribute('disabled', true);

		let kuvat = [];
		const kuva = window.pageDataJSON.projects[category][projectId].kuva;
		if (kuva) {
			kuvat.push(window.pageDataJSON.projects[category][projectId].kuva)
		}

		window.ContentTools.EditorApp.get().removeData(true, 'projects', category, projectId, kuvat, (success) => {
			if (success) {
				this.setState({ removed: true });
			}
			deleteBtn.removeAttribute('disabled');
		});
	}

	stringToHTML = (str) => {
		 var tmp = {__html: str};
		 return <span dangerouslySetInnerHTML={tmp} />
	}

	goToProject(url) {
		window.location.href = url;
	}

	render() {

		if (this.state.removed) return null;

		const projekti = this.props.project;

		if (!projekti) {
			return (
				<div className="project-list-item">
					<Link to={`${this.props.baseURL}/${this.props.category.url}/uusi-projekti`}>
						<button className="new-project btn btn-warning"><h3>Luo uusi projekti</h3></button>
					</Link>
				</div>
			)
		} else {
			var bgImg = null;
			if (projekti.kuva) {
				let imgName = (projekti.kuva) ? (this.state.useOriginalImg) ? projekti.kuva.replace(/_.\./, '_o.') : projekti.kuva.replace(/_.\./, '_i.') : null;
				bgImg = React.createElement('div', {className: 'p-main-icon', style: {backgroundImage: `url(/static/media${imgName})`}}, null);
			}

			if (!this.state.useOriginalImg && projekti.kuva) {
				let imgTmp = document.createElement('img');
				imgTmp.onerror = () => { this.setState({ useOriginalImg: true }) }
				imgTmp.src = '/static/media'+projekti.kuva.replace(/_.\./, '_i.')+'?a=0';
				imgTmp.onload = () => { imgTmp = null }
			}

			return (
				<div className="project-list-item">
					<div className="row">
						<div className="col-lg-4 col-md-3 col-sm-4">
							{bgImg}
						</div>
						<div className="col-lg-8 col-md-8 col-sm-8 p-list-item-info-box row">
							<div className="col-lg-12 col-md-10 col-sm-12">
								<h3>{this.stringToHTML(projekti.nimi)}</h3>
								<h5 className="subtitle">{this.stringToHTML(projekti.sijainti)} <strong>/ </strong> {this.stringToHTML(projekti.vuosi)} <strong>/</strong> {this.stringToHTML(projekti.brm2)} brm<sup>2</sup></h5>
							</div>
							<div className="col-lg-12 col-md-2 col-sm-12">
								<Link to={`${this.props.baseURL}/${this.props.category.url}/${projekti.nimi.replace(/\<.*?>/g, '').replace(/\&.*?\;/g, '').trim().replace(/ /g, '-').replace(/\//g, '%2F').toLowerCase()}`}>
									<button className="edit-project btn btn-success">Muokkaa</button>
								</Link>
								<button onClick={() => this.deleteProject()} id="p-delete-btn" className="delete-project btn btn-danger">Poista</button>
							</div>
						</div>
					</div>
				</div>
			);
		}
	 }

};
