import React from 'react';
import ComponentContactAvatars from '../ComponentContactAvatars/component-contact-avatars';
import GoogleMap from '../GoogleMap/google-map';

const SectionStaff = () => {

   return (
      <span>
         <div className="container-fluid">

            <div className="row">
               <div className="col-xs-12 col-lg-12 v-h-center contact-component">
                     <ComponentContactAvatars />
               </div>
            </div>

            <div className="row">

               <div className="col-12 col-map">
                  <div className="map-container">
                     <GoogleMap lat={61.499906} lng={23.749248}/>
                  </div>
               </div>

            </div>

         </div>
      </span>
   )
}

export default SectionStaff
