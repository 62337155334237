export default class Staff {

   /**
    * @param {string} staffId
    * @param {boolean} isPublished
    * @param {boolean} isShown
    * @param {number} order
    */
   constructor(staffId, isPublished, isShown, order) {
      this.staffId = staffId
      this.isPublished = isPublished
      this.isShown = isShown
      this.order = order
   }

   static fromJson(json) {
      const { staffId, isPublished, isShown, order } = json
      return new Staff(staffId, isPublished, isShown, order)
   }

}
