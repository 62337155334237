import React from 'react';
import ComponentAbout from '../ComponentAbout/component-about';

const SectionAbout = () => {
    
    const pageId = 'about-us'

    return (
        <section className="sectionGray">
            
            <div className="container">
                <div className="row">
                    <ComponentAbout pageId={pageId} componentId="about-1" />
                    <ComponentAbout pageId={pageId} componentId="about-2" />
                    <ComponentAbout pageId={pageId} componentId="about-3" />
                </div>
            </div>
            
        </section>
    );
    
};
            
            
            
export default SectionAbout;