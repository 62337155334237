import React from 'react';
import styles from './section-contact-us.css'
import ButtonMore from '../ButtonMore/button-more';
import { EditableContent } from '../../lib/editable-content/';

const SectionContactUs = () => {

    const pageId = 'main-page';
    const header = { pageId: pageId, contentId: 'aboutHeader', defaultTag: 'h2', enabledTools:['Heading', 'Subheading', 'Paragraph', 'Bold', 'Italic', 'Link', 'NewParagraph'] };

    return (
        
        <section className="contact-us-section sectionGray">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="section-contact-text-box">
                            <EditableContent settings={header}/>
                        </div>
                        <ButtonMore to="/yhteystiedot" title="Ota yhteyttä" style="button-more-bigger"/>
                    </div>
                </div>
            </div>
        </section>
    
    );
    
};
            
            
            
export default SectionContactUs;