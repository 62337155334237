import React from 'react';
import ReactDOM from 'react-dom';

import 'bootstrap/dist/css/bootstrap.min.css'
import App from './App';
import { unregister } from './registerServiceWorker';
import "babel-polyfill";

import rootReducer from './redux/reducers/rootReducer';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import jwt from 'jsonwebtoken';
import setAuthorizationToken from './utils/setAuthorizationToken';
import { setCurrentUser, refreshAuthentication } from './redux/actions/authActions';

const createStoreWithMiddleware = applyMiddleware(thunk)(createStore);

window.$ = window.jQuery = require('jquery')  // Required for Bootstrap
window.Tether = require('tether')  // Required for Bootstrap
window.Popper = require('popper.js')  // // Required for Bootstrap
require('bootstrap')  // Needed only for Bootstrap JavaScript features

const store = createStore(
	rootReducer,
	compose(
		applyMiddleware(thunk),
		window.devToolsExtension ? window.devToolsExtension() : f => f
	)
);

refreshAuthentication();

ReactDOM.render(
	<Provider store={store}>
		<App/>
	</Provider>, document.getElementById('root')
);

unregister();
