import React, { Component } from 'react';

//import { connect } from 'react-redux';
//import {bindActionCreators} from 'redux';


import MainHeader from '../MainHeader/main-header';
import SectionIntro from '../SectionIntro/section-intro';
import SectionProjects from '../SectionProjects/section-projects';
import SectionAboutUs from '../SectionAboutUs/section-about-us';
import SectionContactUs from '../SectionContactUs/section-contact-us';
import NavBar from '../NavBar/navbar';


export default class PageHome extends Component {
    
  render() {
    return (
        <span>

            <NavBar style={"d-lg-none d-xs-block"} />
            <MainHeader />
            <SectionIntro />
            <SectionProjects />
            <SectionAboutUs />
            <SectionContactUs />
        
        </span>
    );
  }
}
