import React, {Component} from 'react';
import './navbar.css';
import {Link} from 'react-router-dom';
import logo from '../../assets/vihanto-logo-black.svg'
import logoWhite from '../../assets/vihanto-logo-white.svg'
import menu from '../../assets/menu.png'

export default class NavBar extends Component {
	
	getLogo() {
		
		if (this.props.style != null) {
			if (this.props.style.includes("transparent")) {
				return logoWhite;
			} else {
				return logo;
			}
		} else {
			 return logo;   
		};
		
	}
	

	render() {
		
		return (

			<nav className={`navbar navbar-expand-lg bg-faded center ${this.props.style}`} id="main-navbar">

				<button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
				<img src={menu}></img>
				</button>

				<Link to="/" className="navbar-brand">
					<img src={this.getLogo()} className="navbar-logo" />
					<span className="sr-only">(current)</span>
				</Link>

				<div className="collapse navbar-collapse" id="navbarNavAltMarkup">

					<ul className="navbar-nav ml-auto">
						<li className="nav-item active">
							<Link to="/" className="nav-link">ETUSIVU <span className="sr-only">(current)</span></Link>
						</li>
						<li className="nav-item">
							<Link to="/tietoa-meista" className="nav-link">TIETOA MEISTÄ</Link>
						</li>
						<li className="nav-item">
							<Link to="/projektit" className="nav-link">PROJEKTIT</Link>
						</li>
						<li className="nav-item">
							<Link to="/yhteystiedot" className="nav-link">YHTEYSTIEDOT</Link>
						</li>
					</ul>
				</div>
			</nav>

		);
	};
	
};