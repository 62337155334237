import React from 'react';
import styles from './component-about.css'
import { EditableContent } from '../../lib/editable-content/';

const ComponentAbout = (props) => {

    const header = { pageId: props.pageId, contentId: props.componentId+'-header', defaultTag: 'h3', singleParagraph: true, maxLines: 1, enabledTools:[] };
    const text = { pageId: props.pageId, contentId: props.componentId+'-text', enabledTools:[] };

    return (
        <div className="col-sm-12 col-md-4">
            <div className="row">
                <div className="col-12 centerAlign">
                    <EditableContent settings={header}/>
                </div>
                <hr className="shortLine"></hr>
                <div className="col-12 centerAlign">
                    <EditableContent settings={text}/>
                </div>
            </div>
        </div> 
    
    );
    
};
            
            
            
export default ComponentAbout;