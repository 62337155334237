export function initAppearOnScroll() {

	const onScrollListener = () => {
		var bottom_of_window = window.pageYOffset + window.$(window).height();
		window.$('.appearOnScroll').each(function (i) {
			var bottom_of_object = window.$(this).offset().top + window.$(this).outerHeight() / 4;
			if (bottom_of_window > bottom_of_object) {
				window.$(this).animate({
					'opacity': '1'
				}, 750);
			}

		});

	}

	document.addEventListener("scroll", onScrollListener);

}