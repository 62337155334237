import React, {Component} from 'react';
import styles from './button-small-light.css'
import {Link} from 'react-router-dom';

export default class ButtonSmall extends Component {
    
    render() {
        
         return (

            <div className="buttonBox center-this">
                 
                 <Link to="/yhteystiedot" className="button-small-light">
                    {this.props.title}
                 </Link>
                 
            </div>
             
             

            );   
        
    }
    
};
