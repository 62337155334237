import axios from 'axios'
import Staff from '../models/Staff'

const corsAnywhereUrl = process.env.REACT_APP__CORS_ANYWHERE || ''
const API_URL = corsAnywhereUrl + 'https://8elmbyr4ch.execute-api.eu-central-1.amazonaws.com/production/staff'

export default class StaffService {

   /**
    * @returns {import('../models/Staff').default[]}
    */
   static async getStaff() {
      const response = await axios.get(API_URL)
      if (!response.data.length) return []
      return response.data.map((staff) => (Staff.fromJson(staff))).sort((a, b) => a.order > b.order ? 1 : -1)
   }

   /**
    *
    * @param {string[]} staff Array of ID's
    * @param {*} token Auth token
    */
   static async saveStaff(staff, token) {
      const response = await axios.post(API_URL, staff, {
         headers: {
            'Authorization': token,
         }
      })
      return response.data
   }

   static async publishStaff(staff, token) {
      const staffToPublish = staff.map((member) => {
         if (member.isShown) return { ...member, isPublished: true }
         else return { ...member, isPublished: false }
      })
      const response = await axios.post(API_URL, staffToPublish, {
         headers: {
            'Authorization': token,
         }
      })
      return response.data
   }

}
