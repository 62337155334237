import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { refreshAuthentication, signOut } from './redux/actions/authActions';
import './App.css';
import Footer from './components/Footer/footer';
import PageContact from './components/PageContact/page-contact';
import PageHome from './components/PageHome/page-home';
import PageInfo from './components/PageInfo/page-info';
import PageProjects from './components/PageProjectsEditor/page-projects';
import PageSignIn from './components/PageSignIn/page-signin';
import { initAppearOnScroll } from './utils/appearOnScroll';
import { initContentEditor } from './utils/contentEditor';
import RouteChangeListener from './utils/routeChangeListener';
import RouterScrollTop from './utils/RouterScrollTop';
import ProjectService from './services/ProjectService';
import { setProjectOrder } from './redux/actions/projectOrderActions';



const App = () => {

	const [editorInitialized, setEditorInitialized] = useState(false)
	const { isAuthenticated, token } = useSelector((state) => state.auth)
	const dispatch = useDispatch()

	function refreshAuthenticationPeriodically() {
		dispatch(refreshAuthentication())
	}

	useEffect(() => {
		initAppearOnScroll()
		refreshAuthenticationPeriodically()
	}, [])

	useEffect(() => {
		getProjectPageOrders()
	}, [])

	async function getProjectPageOrders() {
		const orders = await ProjectService.getProjectOrders()
		if (orders) {
			for (const { category, order } of orders) {
				dispatch(setProjectOrder(category, order))
			}
		}
	}

	useEffect(() => {
		if (isAuthenticated && !editorInitialized) {
			initContentEditor(token, () => {
				dispatch(signOut())
				window.clearInterval(refreshAuthenticationPeriodically)
				setEditorInitialized(false)
			});
			window.setInterval(refreshAuthenticationPeriodically, 300000)
			setEditorInitialized(true)
		}
	}, [isAuthenticated])

	function onRouteChanged(from, to) {
		window.ContentTools.EditorApp.get().reInit(true, false, false)
	}

	const unauthElements = (
		<div>
			<Switch>
				{/* <Route path='/unohtuiko-salasana' component={PageForgotPassword}/> */}
				<Route path='/' component={PageSignIn}/>
			</Switch>
		</div>
	)

	const authElements = (
		<div>
			<Switch>
				<Route path={`/projektit/:category?/:project?`} component={PageProjects}/>
				<Route path="/yhteystiedot" component={PageContact}/>
				<Route path="/tietoa-meista" component={PageInfo}/>
				<Route path="/" component={PageHome}/>
			</Switch>

			<Footer/>
		</div>
	)

	return (
		<BrowserRouter>
			<RouterScrollTop>

				<RouteChangeListener onRouteChanged={onRouteChanged}/>

				{ isAuthenticated ? authElements : unauthElements }

			</RouterScrollTop>
		</BrowserRouter>
	)

}

export default App
