import React, { Component } from 'react';
import './modal-change-password.css';


export default class ModalChangePassword extends Component {

	constructor(props) {
		super(props);

		this.state = {
			password: '',
			passwordVerify: '',
			error: null,
			setNewPassword: null,
			isLoading: false,
			isShowing: false
		}

		this.onChange = this.onChange.bind(this);
	}

	componentDidUpdate() {
		if (this.state.setNewPassword && !this.state.isShowing) this.show();
		else if (!this.state.setNewPassword && this.state.isShowing) this.hide();
	}

	show() {
		this.setState({
			password: '',
			passwordVerify: '',
			error: null,
			isShowing: true
		});
		window.$(this.refs.changePasswordModal).modal('show');
		setTimeout(() => { this.refs.passwordInput.focus(); }, 200)
	}
	hide() {
		window.$(this.refs.changePasswordModal).modal('hide');
		this.setState({
			setNewPassword: null,
			isShowing: false
		});
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	render() {

		const { password, passwordVerify, error, isLoading} = this.state;

		return (
			<div className="modal fade" id="newPasswordModal" data-backdrop="static" data-keyboard="false" ref={"changePasswordModal"} role="dialog">
				<div className="modal-dialog" role="document">
					<div className="modal-content">
						<form onSubmit={this.props.onSubmit}>
							<div className="modal-header">
								<h5 className="modal-title">Vaihtakaa väliaikainen salasana jatkaaksenne.</h5>
							</div>
							<div className="modal-body">
								<input
									type="password"
									name="password"
									placeholder="Uusi salasana"
									value={password}
									onChange={this.onChange}
									className="form-control passwordInput"
									ref="passwordInput"/>
								<input
									type="password"
									name="passwordVerify"
									placeholder="Vahvista salasana"
									value={passwordVerify}
									onChange={this.onChange}
									className="form-control passwordInput"/>
								<p className="text-danger warningText">{error}</p>
							</div>
							<div className="modal-footer">
								<button className="btn btn-success" disabled={isLoading}>Vaihda salasana</button>
								<button type="button" className="btn btn-danger" onClick={() => {this.setState({ setNewPassword: null })}} disabled={isLoading}>Sulje</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		);

  	}

}
