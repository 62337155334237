import { AuthenticationDetails, CognitoUserPool, CognitoUser } from 'amazon-cognito-identity-js'
import $ from 'jquery'
import setAuthorizationToken from '../../utils/setAuthorizationToken'
import jwt from 'jsonwebtoken'
import { SET_CURRENT_USER } from './types';


const POOL_DATA = {
	UserPoolId : 'eu-central-1_bzemGAP0F',
	ClientId : '12hgr5g09sg2fkhaceb1moh8lh'
};

var userPool = new CognitoUserPool(POOL_DATA);


export function setCurrentUser(user, token) {
	return {
		type: SET_CURRENT_USER,
		user,
		token
	};
}


export function signIn(userData) {

	return dispatch => {

		return new Promise((resolve, reject) => {

			let errors = {};

			const validation = validateUserSignInData(userData);

			if (!$.isEmptyObject(validation)) {
				errors.input = validation;
				return reject(errors);
			}

			const authData = {
				Username: userData.username,
				Password: userData.password
			};
			const authDetails = new AuthenticationDetails(authData);
			const cognitoUserData = {
				Username: userData.username,
				Pool: userPool
			};

			const cognitoUser = new CognitoUser(cognitoUserData);
			cognitoUser.authenticateUser(authDetails, {
				onSuccess: (result) => {
					dispatch(saveUserSession(result));
					resolve({ requireNewPassword: false });
				},
				onFailure: (err) => {
					console.log(err.code);
					switch(err.code) {
						case 'UserNotFoundException':
						case 'NotAuthorizedException':
							//errors.request = 'Väärä käyttäjätunnus tai salasana!';
							errors.input = { password: 'Väärä salasana' };
							reject(errors);
							break;
						default:
							errors.request = 'Tapahtui jokin virhe!';
							reject(errors);
					}
				},
				newPasswordRequired: (userAttributes, requiredAttributes) => {
					delete userAttributes.email_verified;
					resolve({
						requireNewPassword: true,
						setNewPassword: (password, passwordVerify) => {

							return new Promise((resolve, reject) => {

								const error = null;

								const validationError = validatePasswords(password, passwordVerify);
								if (validationError) return reject(validationError);

								cognitoUser.completeNewPasswordChallenge(password, userAttributes, {
									onSuccess: (result) => {
										dispatch(saveUserSession(result));
										resolve();
									},
									onFailure: (err) => {
										reject('Tapahtui jokin virhe!');
									}
								});
							});

						}
					});
				}
			});
		});
	}
}

function validateUserSignInData(userData) {
	let errors = {};

	if (!userData.username) {
		errors.username = 'Käyttäjänimi vaaditaan.'
	}
	if (!userData.password) {
		errors.password = 'Salasana vaaditaan.'
	}

	return errors;
}



function validatePasswords(pwd, pwdVrfy) {
	let error = null;

	if (!pwd.match(/^(?=.*\d)(?=.*[A-Z])(?=.*[a-z]).{8,}$/g)) {
		error = "Salasanan tulee olla vähintään 8 merkkiä pitkä, siinä täytyy olla pieniä ja isoja kirjaimia sekä numeroita!";
	} else if (pwdVrfy !== pwd) {
		error = "Salasanat eivät täsmää!";
	}

	return error;
}



function getAuthenticatedUser() {
	return userPool.getCurrentUser();
}


export function refreshAuthentication() {

	return dispatch => {

		return new Promise((resolve, reject) => {
			const user = getAuthenticatedUser();
			if (!user) {
				dispatch(removeUserSession());
				//reject();
			} else {
				user.getSession((err, session) => {
					if (err) {
						dispatch(removeUserSession());
						//reject();
					} else {
						if (session.isValid()) {
							dispatch(saveUserSession(session))
							//resolve();
						} else {
							dispatch(removeUserSession());
							//reject();
						}
					}
				});
			}
		});
	}

}




export function signOut() {
	return dispatch => {
		const user = getAuthenticatedUser();
		if (user) getAuthenticatedUser().signOut();
		dispatch(removeUserSession());
	}
}


function removeUserSession(dispatch) {
	//setAuthorizationToken(false);
	return setCurrentUser({}, null);
}

function saveUserSession(userSession) {
	//setAuthorizationToken(userSession.idToken.jwtToken);
	return setCurrentUser(jwt.decode(userSession.idToken.jwtToken), userSession.idToken.jwtToken);
}
