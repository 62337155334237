import React from 'react';
import './main-header.css';
import ScrollDownArrow from '../ScrollDownArrow/scroll-down-arrow';
import NavBar from '../NavBar/navbar'
import { EditableContent, EditableBackground } from '../../lib/editable-content/';

const MainHeader = () => {
    const pageId = 'main-page'
    const background = { pageId: pageId, contentId:'headerBackground', resolution:[1920, 1080] }
    const header = { pageId: pageId, contentId:'headerTitle', defaultTag: 'h2', singleParagraph: true, maxLines: 1, enabledTools:[] }
    return (
        
        <EditableBackground className="main-header" id="main-header" settings={background}>
            <span className="nav-box">
                <NavBar style="transparent d-none d-lg-block"/>
            </span>
                
            <div className="row header-dark-layer main-header-title-box"></div>
                
                <div className="header-title-container">
                    
                        <div className="center-header-title-container">
                            <EditableContent settings={header}/>
                        </div>     
                    </div>
                
                <ScrollDownArrow />
            
        </EditableBackground>
    );
    
};
            
            
            
export default MainHeader;