import { SET_CURRENT_USER } from '../actions/types'
import $ from 'jquery'

const initialState = {
	isAuthenticated: false,
	user: {}
};

export default (state = initialState, action = {}) => {
	
	switch (action.type) {
		case SET_CURRENT_USER:
			return {
				isAuthenticated: !$.isEmptyObject(action.user),
				user: action.user,
				token: action.token
			};
		default: return state;
	}
}