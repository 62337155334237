import React, { Component } from 'react';

class Loadable extends Component {
	constructor(props) {
		super(props);
		this.state = { pageJSONloaded: false}
	}

	componentDidMount() {
		const props = this.props;
		if (!props.settings) throw new Error("settings parameter is missing.");
		const pageId = props.settings.pageId;
		const contentId = props.settings.contentId;
		const dataId = props.settings.dataId;
		const groupId = props.settings.groupId;
		const objectId = props.settings.objectId;
		//if ((!dataId || !groupId || !objectId || !contentId) && (!pageId || !contentId)) throw new Error("settings parameter must contain 'dataId, groupId, objectId and contentId' or 'pageId and contentId'");
		//if (dataId && pageId) throw new Error("settings parameter must contain 'dataId, groupId, objectId and contentId' or 'pageId and contentId' not all of them");
		if (pageId) this.getPagesData();
	}

	getPagesData() {
		GetData('pages', function() {
			if (this.state.pageJSONloaded) return;
			this.setState({ pageJSONloaded: true});
		}.bind(this));
	}

	generateComponentFromPageDataJSON(type) {
		const props = this.props;
		const pageData = window.pageDataJSON;
		const settings = props.settings;
		const pageId = settings.pageId;
		const contentId = settings.contentId;
		let tag = (settings.tag) ? settings.tag : 'div';
		let attributes = {}
		for (const name in props) {
			if (name === 'settings' || name === 'children') continue;
			attributes[name] = props[name];
		}
		if (!pageData.pages || !pageData.pages[pageId]) return React.createElement(tag, attributes, props.children);
		let children;
		switch (type) {
			case 'Content':
				let innerHTML;
				const regions = pageData.pages[pageId].regions;
				if (regions && regions[contentId]) {
					innerHTML = regions[contentId].html;
				}
				if (!innerHTML) children = props.children;
				else attributes['dangerouslySetInnerHTML'] = {__html: innerHTML};
				break;
			case 'Background':
				const backgroundHolders = pageData.pages[pageId].backgroundHolders;
				const corsAnywhereUrl = process.env.REACT_APP__CORS_ANYWHERE || ''
				const contentUrl = process.env.REACT_APP__CONTENT_URL || ''
				if (backgroundHolders && backgroundHolders[contentId]) {
					attributes.style = Object.assign({}, attributes.style, { 'backgroundImage': 'url(' + corsAnywhereUrl + contentUrl + '/static/media' + backgroundHolders[contentId].src+')' }); // ?a=0 disable cache on editor
				}
				children = props.children;
			default:
		}
		return React.createElement(tag, attributes, children);
	}

}

export class LoadContent extends Loadable {
	render() {
		if (!window.pageDataJSON) return <div id={this.props.id} className={this.props.className}>{this.props.children}</div>
		return this.generateComponentFromPageDataJSON('Content');
	}
}

export class LoadBackground extends Loadable {
	render() {
		if (!window.pageDataJSON) return <div id={this.props.id} className={this.props.className}>{this.props.children}</div>
		return this.generateComponentFromPageDataJSON('Background');
	}
}


export default function GetData(name, callback) {
	const loadComplete = () => {
		const data = (window.pageDataJSON[name]) ? window.pageDataJSON[name] : {};
		window.pageDataJSONloader.removeEventListener('loadComplete', loadComplete, false);
		callback(data);
	}
	if (window.pageDataJSON) loadComplete();
	else window.pageDataJSONloader.addEventListener('loadComplete', loadComplete, false);
}
