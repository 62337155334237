import React, { useEffect, useRef } from 'react'
import './dialog.css'

/**
 * @typedef DialogProps
 * @property {boolean} isShown
 * @property {string} title
 * @property {string} description
 * @property {Function} onConfirm
 * @property {Function} onCancel
 */
/**
 * @param {DialogProps} props
 * @returns {import('react').ReactElement}
 */
const Dialog = ({ isShown, title, description, onConfirm, onCancel, isLoading, onClose }) => {

   const backgroundRef = useRef()

   const onBackgroundClick = (e) => {
      if (e.target.className === 'dialog--background') onClose()
   }

   useEffect(() => {
      window.addEventListener('click', onBackgroundClick)
      return () => window.removeEventListener('click', onBackgroundClick)
   }, [])

   if (!isShown) return null

   return (
      <div className='dialog--background' ref={backgroundRef}>
         <div className='dialog--box'>
            <h3>{title}</h3>
            <p>{description}</p>
            <div className='dialog--box--buttons'>
               <button
                  className='dialog--button dialog--button__green'
                  onClick={onConfirm}
                  disabled={isLoading}
               >
                  Vahvista
               </button>
               <button
                  className='dialog--button dialog--button__red'
                  onClick={onCancel}
                  disabled={isLoading}
               >
                  Peruuta
               </button>
            </div>
         </div>
      </div>
   )
}

export default Dialog
