import React, { Component } from 'react';
import { connect } from 'react-redux';
import { signIn } from '../../redux/actions/authActions'
import FormSignIn from '../FormSignIn/form-signin';
import ModalChangePassword from '../ModalChangePassword/modal-change-password';


class PageSignIn extends Component {

	constructor(props) {
		super(props);

		this.onSubmitSignIn = this.onSubmitSignIn.bind(this);
		this.onSubmitChangeTemporaryPassword = this.onSubmitChangeTemporaryPassword.bind(this);
	}

	onSubmitChangeTemporaryPassword(e) {
		e.preventDefault();
		const changePasswordModal = this.refs.changePasswordModal;
		changePasswordModal.setState({
			isLoading: true,
			error: null
		});
		const { password, passwordVerify, setNewPassword } = this.refs.changePasswordModal.state;
		setNewPassword(password, passwordVerify).then(
			(data) => {
				this.refs.changePasswordModal.setState({
					setNewPassword: null
				});
				//this.signedIn();
			}
		).catch(
			(error) => {
				this.refs.changePasswordModal.setState({
					error: error,
					isLoading: false
				});
			}
		);
	}

	onSubmitSignIn(e) {
		e.preventDefault();
		const signInForm = this.refs.signInForm;
		signInForm.setState({
			isLoading: true,
			errors: {}
		});
		this.props.signIn(signInForm.state).then(
			(data) => {
				if (data.requireNewPassword) {
					signInForm.setState({
						isLoading: false,
						errors: {}
					});
					this.refs.changePasswordModal.setState({ setNewPassword: data.setNewPassword });
				} else {
					//this.signedIn();
				}
			}
		).catch(
			(errors) => {
				signInForm.setState({
					isLoading: false,
					errors: errors,
					focusOnErrorInput: true
				});
			}
		);
	}


	signedIn() {
		window.location.replace('/');
		//this.context.router.history.push('/');
	}


	render() {

		return (
			<div>
				<ModalChangePassword ref="changePasswordModal" onSubmit={this.onSubmitChangeTemporaryPassword}/>

				<div className="container">
					<div className="row">

						<div className="col-md-1 col-lg-2"/>

						<FormSignIn className="col-md-10 col-lg-8" ref="signInForm" onSubmit={this.onSubmitSignIn}/>

						<div className="col-md-1 col-lg-2"/>

					</div>
				</div>
			</div>
		);
  	}

}

export default connect(null, { signIn } )(PageSignIn);
