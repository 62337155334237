import React, { Component } from 'react';
import './page-info.css';
import SectionTitle from '../SectionTitle/section-title'
import ButtonSmallLight from '../ButtonSmallLight/button-small-light'
import SectionAbout from '../SectionAbout/section-about'
import NavBar from '../NavBar/navbar';
import { EditableContent, EditableBackground } from '../../lib/editable-content/';

class PageInfo extends Component {
  render() {
    const pageId = 'about-us';
    const mainBgImg = { pageId: pageId, contentId: 'mainBgImg', tag:'section', resolution: [1920, 1080] };
    const header = { pageId: pageId, contentId: 'mainHeader', enabledTools:[] };
    const text = { pageId: pageId, contentId: 'mainTxt', enabledTools:['Subheading', 'Paragraph', 'Bold', 'Italic', 'Link', 'NewParagraph' ] };
    const bgImg2 = { pageId: pageId, contentId: 'aboutUsBgImg2', tag:'section', resolution: [1920, 1080] };
    const text2 = { pageId: pageId, contentId: 'aboutUsTxt2', headingTag: 'h2', enabledTools:['Subheading', 'Paragraph', 'Bold', 'Italic', 'Link', 'NewParagraph' ] };

    return (
        <span>
            <NavBar />
        
            <EditableBackground className="page-info-header" settings={mainBgImg}/>

            <section className="section-padding">
                
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12 jumbotron-fluid intro-jumbotron container">
                            <EditableContent className="PageTitle" settings={header}/>
                            <hr className="shortLine"></hr>
                        </div>
                    </div>
                        
                </div>

                <EditableContent className="row center-this info-text-box" settings={text}/>
                    
            </section>
            
            
            <SectionAbout />
            

            <EditableBackground className="bgImageSection v-h-center" settings={bgImg2}>
                
                <div className="info-small-text-container">
                    <EditableContent settings={text2}/><br/>
                    <ButtonSmallLight title="Ota yhteyttä" style={"light"}/>
                </div>
                    
            </EditableBackground>
            

        </span>
    );
  }
}

export default PageInfo;