import React from 'react';
import './section-intro.css'
import ButtonMore from '../ButtonMore/button-more'
import { EditableContent } from '../../lib/editable-content/';

const SectionIntro = () => {

    const pageId = 'main-page';
    const header = { pageId: pageId, contentId: 'introHeader', defaultTag: 'h1', singleParagraph: true, maxLines: 1, enabledTools:[] }
    const text = { pageId: pageId, contentId: 'introText', enabledTools:['Bold', 'Italic', 'Link', 'NewParagraph'] }

    return (
        <section id="section-intro">
        
        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-12">
                    <div className="jumbotron-fluid intro-jumbotron">
                        <div className="container">
                        <EditableContent className="PageTitle" settings={header}/>
                            <hr className="shortLine"></hr>
                        </div>
                    </div>
                </div>
            </div>
                
            <div className="row">
                <div className="col-12 intro-text-box center-this">
                    <EditableContent settings={text}/>
                </div>
                <ButtonMore to="/tietoa-meista" title="Lue Lisää"/>
            </div>
        </div>
            
    </section>
    
    );
    
};
            
            
            
export default SectionIntro;