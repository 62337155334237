import React, {Component} from 'react';
import testImage from '../../assets/kuva1.jpeg';
import styles from './component-project.css';
import {Link} from 'react-router-dom';
import { EditableBackground } from '../../lib/editable-content/';

class ComponentProject extends Component {
    
    render() {
        const pageId = 'main-page'
        const background = { pageId: pageId, contentId:this.props.bgImage, resolution:[960, 720] }
        
        return (
            <div className="col-sm-12 col-md-6 col-xl-4 col-project-container">
                <Link to={this.props.to}>
            
                <EditableBackground className={`projectContainer`} settings={background} >
                    <div className="row project-dark-layer">
                    </div>
                </EditableBackground> 
                
                <div className="project-title-container">
                    <div className="center-title-container">
                        <h3>{this.props.title}</h3>
                    </div>     
                </div>
                
                </Link>
            </div>
            
        );   
    }
    
};
            
            
            
export default ComponentProject;