import React, { Component } from 'react';
import styles from './scroll-down-arrow.css';



class ScrollDownArrow extends Component {
    
    scrollDown() {
        
        var offsetTop = window.$("#section-intro").offset().top;
        var scrollDuration = 750;
        
        // console.log(offsetTop);
        // console.log(scrollDuration);
        
        window.$('html, body').animate({
		scrollTop: offsetTop
	   }, scrollDuration, 'swing');
        
    }
    
  render() {
    return (
        
        <div className="arrow-row">
            <div className="arrow-container center-this" onClick={this.scrollDown.bind(this)}>
                <div className="down-arrow center-this"></div>
            </div>
        </div>
        
        
    );
  }
}

export default ScrollDownArrow;