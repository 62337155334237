import React, {Component} from 'react';
import styles from './section-title.css'

class SectionTitle extends Component {
    
    render() {
        
         return (

                <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="jumbotron-fluid intro-jumbotron">
                            <div className="container">
                                <h2 className="section-title">{this.props.text}</h2>
                                
                            </div>
                        </div>
                    </div>
                </div>
                </div>

            );   
        
    }
    
};
            
            
            
export default SectionTitle;