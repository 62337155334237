import React, {Component} from 'react';
import styles from './header-contact.css';
import { EditableContent, EditableBackground } from '../../lib/editable-content/';

export default class HeaderContact extends Component {
    
    componentDidMount() {
		document.getElementById('headerInfoBox').className += ' slide-from-left';
        /*var bgImg = new window.Image();
        bgImg.onload = function() {
            //var img = document.getElementById('contactHeader');
            //img.style.backgroundImage = 'url(' + bgImg.src + ')';
            document.getElementById('headerInfoBox').className += ' slide-from-left';
        };
        bgImg.src = bgImage;*/
    }
    
    
    render() {
        const pageId = 'contact';
        const mainBgImg = { pageId: pageId, contentId: 'mainBgImg', resolution: [1920, 1080] };
        const text = { pageId: pageId, contentId: 'osoiteTxt', enabledTools:['Subheading', 'Paragraph', 'Bold', 'Italic', 'Link', 'NewParagraph' ] };
        
        return (
            <EditableBackground id="contactHeader" className="contact-header" settings={mainBgImg}>
            
                    <div id="headerInfoBox" className="header-contact-info-box">
                        <EditableContent settings={text}/>
                    </div>
            
            </EditableBackground>
        );
    };
    
};
